.dropdown {
  position: relative;
  display: inline-block;

  .dropdown-content {
    display: none;
    position: absolute;
    background: #ffffff;
    min-width: 160px;
    padding: 10px 14px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    z-index: 1;

    .country-filter {
      // width: 100%;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.12);
      margin-bottom: 10px;
      font-size: 14px;
      padding: 5px;
    }
    .list-items {
      max-height: 300px;
      overflow: hidden;
      overflow-y: scroll;
      /* width */
      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #ffffff;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  .dropdown-content a:hover {
    color: rgba(0, 0, 0, 1);
  }

  .dropdown-content a {
    color: rgba(0, 0, 0, 0.8);
    padding: 5px 0;
    text-decoration: none;
    display: block;
  }
}

input {
  outline: none;
}
