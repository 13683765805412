.search-item {
  width: 95%;
  .href-link {
    display: block;
    margin-top: 25px !important;
    cursor: pointer;
    &:hover h2 {
      text-decoration: underline !important;
    }
  }
  .link-url {
    display: flex !important;
    font-size: 12px !important;
    line-height: 1.3 !important;
    align-items: center !important;
    color: #5f6368 !important;
    div {
      max-width: 150px !important;
      white-space: nowrap !important;

      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
    &.url-0 {
      max-width: none !important;
      color: #202124 !important;
    }
  }
  h2 {
    font-size: 19px;
    line-height: 18px;
    display: block;
    font-weight: normal;
    color: #1a0dab;
    margin-top: 0;
    margin-bottom: 5px;
  }
  p {
    width: 100%;
    font-size: 13px;
    margin-top: 7px;
    line-height: 1.4;
    color: #4d5156;
  }

  a {
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #202124;
    cursor: pointer;
    margin-bottom: 5px;
  }
  button {
    font-size: 10px;
    line-height: 14px;
    color: green;
    margin-bottom: 0px;
    padding: 0px;
    border-width: 0px;
    background-color: white;
  }
}
