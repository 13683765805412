.search-noResult {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 510px) {
    flex-direction: column;
    text-align: center;
  }
  img {
    width: 120px;
    height: 120px;
    margin-right: 30px;
  }
  @media (max-width: 510px) {
    flex-direction: column-reverse;
    text-align: center;
  }
  h2 {
    color: #202124;
    margin-bottom: 0;
  }
  p {
    color: #202124;
    font-weight: 400;
    font-size: 14px;
    span {
      font-weight: 500;
      color: rgba($color: #000000, $alpha: 0.9);
    }
  }
  ul {
    li {
      font-weight: 400;
      color: #202124;
      font-size: 14px;
    }
  }
}
