.App {
  margin: 0 auto;
  max-width: 720px;
  min-width: 320px;
  height: 100vh;
  position: relative;
  @media (max-width: 720px) {
    max-width: 98%;
  }
  .top {
    padding-top: 20px;
  }

  .search-result {
    width: 95%;
    margin: 0 auto;
    #result-number {
      font-size: 0.8rem;
      color: gray;
    }
  }
  .initial-home {
    p {
      margin-top: 0 auto;
      width: fit-content;
      line-height: 150%;
      color: rgba($color: #000000, $alpha: 0.7);
      @media (max-width: 680px) {
        font-size: 12px;
      }
      &.footerNote {
        margin-top: 20px;
        font-size: 12px;
        width: 100%;
        text-align: center;
        @media (max-width: 680px) {
          font-size: 10px;
        }
        a {
          text-decoration: none;
          color: #1a0dabd8;

          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &.InspireNote {
        position: absolute;
        text-align: center;
        bottom: 15px;
        width: 100%;
        margin: 0 auto;
        font-size: 11px;
        @media (max-width: 680px) {
          font-size: 10px;
        }
        a {
          text-decoration: none;
          color: #1a0dabd8;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
