.home-component {
  margin: 0 auto;
  margin-top: 100px;

  width: 95%;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  @media (max-width: 720px) {
    max-width: 97%;
  }
  @media (max-width: 340px) {
    flex-direction: column;
    text-align: center;
  }
  img {
    width: 100px;
    height: fit-content;
    // min-width: 150px;
    margin-right: 30px;
    @media (max-width: 720px) {
      min-width: 90px;
      max-height: fit-content;
      margin-right: 10px;
    }
  }
  .home-body {
    margin: 0 auto;
    width: fit-content;
    h1 {
      color: rgba($color: #000000, $alpha: 0.75);
      margin-bottom: 10px;
      @media (max-width: 680px) {
        font-size: 18px;
      }
    }
    p {
      margin-top: 0;
      line-height: 150%;
      color: rgba($color: #000000, $alpha: 0.6);
      @media (max-width: 680px) {
        font-size: 12px;
      }
      &.footerNote {
        font-size: 12px;
        @media (max-width: 680px) {
          font-size: 10px;
        }
        a {
          text-decoration: none;
          color: #1a0dab;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
