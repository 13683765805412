.sr-search-bar {
  padding: 10px 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  //   margin: 0 auto;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  span {
    width: fit-content;
    height: 24px;
  }
  .country {
    cursor: pointer;
    height: fit-content;
  }
  .textBox {
    padding-left: 5px;
    width: 100%;
  }

  input {
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
  }

  #searchBox {
    width: 100%;
    outline: none;
    border: none;
    display: flex;
    margin-right: 8px;
    align-items: center;
  }
  .vLine {
    width: 1px;
    background: #f0f0f0;
    margin: 0 8px 0 8px;
    height: 24px;
  }
  .cancelBtn,
  .searchBtn {
    cursor: pointer;
    svg path {
      fill: #1a0dabb1;
    }
    &:hover {
      svg {
        path {
          fill: #1a0dab;
        }
      }
    }
  }

  .box {
    display: flex;
    align-items: center;
  }

  .clickable {
    cursor: pointer;
  }
}
